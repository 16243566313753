.container {
	height: 100vh;
	min-width: 1364px;
}

.el-header {
	background: #212844;
	color: #F6F7F9;
	text-align: center;
	line-height: 64px;
	position: relative;

	.headLeft {
		position: absolute;
		left: 18px;
		height: 100%;
	}

	.isCollapse {
		height: 50px;
		line-height: 50px;
		cursor: pointer;
		margin-right: 10px;

		img {
			height: 32px;
		}
	}

	.logo {
		/*		position: absolute;
				left: 18px;*/
		margin-right: 10px;
		justify-content: center;

		img {
			height: 44px;
		}
	}

	.dropDown {
		position: absolute;
		right: 40px;
		top: 50%;
		transform: translateY(-50%);
		color: #F6F7F9;
		font-weight: bold;

		h4 {
			margin-right: 40px;
		}

	}

	::v-deep .el-dropdown-selfdefine {
		cursor: pointer;
		color: #FFFFFF;
	}
}

.el-container {
	background: #141930;
}

.el-aside {
	//background-color: #F0F2F8;
	color: #333;
	text-align: center;
	//box-shadow: 0 0 16px 0 rgba(33, 40, 68, 0.08);
	//width: auto !important;

	.el-menu-demo ::v-deep {
		//background: transparent;
		width: 236px;
		margin-top: 10px;

		.el-submenu__title i {
			color: #212844;
		}

		.el-menu,
		.el-submenu__title:hover,
		.el-menu-item:focus,
		.el-menu-item:hover {
			background: transparent;
		}

		.el-menu-item, .el-submenu__title {
			text-align: left;
			padding: 0;
			height: 48px;
			line-height: 48px;

			.menuAct {
				padding-left: 14px;
			}
		}

		.el-menu-item.is-active, .el-submenu.is-active.is-opened {
			.menuAct {
				background: #C3CBE3;
				border-radius: 10px 0 0 10px;
				//font-weight: bold;
				position: relative;
				color: #212844;

				&:after {
					position: absolute;
					content: '';
					width: 4px;
					height: 48px;
					background: #212844;
					right: 0;
				}
			}

			.el-menu-item.is-active {
				color: #0067A6;
				font-weight: bolder;
				border-radius: 10px 0 0 10px;
			}
		}


		.icon {
			width: 20px;
			margin-right: 10px;
		}

		.el-menu-item-group__title {
			padding: 0;
		}
	}

	.el-menu-demo.el-menu--collapse ::v-deep {
		width: auto;

		.icon {
			margin-right: 0;
		}

		.el-menu-item, .el-submenu__title {
			width: fit-content;
		}

		.el-menu-item {
			padding-right: 20px;
			display: flex;
			align-items: center;
		}

		.menuAct {
			padding-left: 0;
			width: 40px;
			height: 40px;
			text-align: center;
			line-height: 40px;
		}

		.el-menu-item.is-active .menuAct {
			background: #C3CBE3;
			border-radius: 8px;
		}

		.el-menu-item.is-active .menuAct:after,
		.el-submenu.is-active.is-opened .menuAct:after {
			display: none;
		}
	}

}

.el-main {
	margin: 11px;
	height: calc(100vh - 86px);
	background: #212844;
	padding: 14px;

	.commonHeight {
		height: 96%;
		overflow-y: scroll;
		margin-bottom: 10px;
		color: #fff;
	}
}

.el-footer {
	//background: #FFFFFF;
	text-align: center;
	line-height: 20px;
	color: rgba(0, 158, 255, 0.5);
	margin: 0 18px;

	a {
		color: rgba(0, 158, 255, 0.5);
		text-decoration: none;
	}
}
