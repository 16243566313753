.bg {
	background: url("~@/assets/img/bg.png") no-repeat center;
	background-size: cover;
	height: 100vh;
	justify-content: center;
}

.login {
	width: 1000px;
	height: 600px;
	background: #FFFFFF;
}

.left {
	background: url("~@/assets/img/leftBg.png") no-repeat center;
	background-size: cover;
	width: 50%;
	height: 100%;
	position: relative;

	.logo {
		width: 240px;
		height: 120px;
		background: #FFFFFF;
		justify-content: center;
		position: absolute;
		top: 32px;
		left: -32px;

		img {
			width: 134px;
		}
	}
}

.right {
	justify-content: center;
	flex-flow: column;
	width: 50%;
	height: 100%;

	h1 {
		position: relative;
		padding-bottom: 20px;
		color: #373D5B;

		&:after {
			position: absolute;
			bottom: 0;
			width: 120px;
			height: 4px;
			background: #D50D0E;
			border-radius: 2px;
			content: '';
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

.formLogin ::v-deep {
	width: 70%;
	margin: 10% auto 0;

	.el-form-item {
		margin-bottom: 30px;
	}

	.el-input__inner {
		background: #F0F2F8;
		border-radius: 10px;
		height: 45px;
		border: none;
		color: #373D5B;
		font-size: 15px;
	}

	.el-input-group__append {
		margin-left: 16px;
		background: transparent;
		border: none;
		width: 50%;
		text-align: center;
		height: 45px;

		.el-button {
			height: 100%;
			border-radius: 10px;
			text-align: center;
			color: #D50D0E;

			img {
				width: 100%;
			}
		}
	}
}

.loginBtn {
	width: 100%;
	height: 45px;
	background: #D50D0E;
	border-radius: 10px;
	color: #FFFFFF;
	border: none;
	font-size: 15px;
}

.tips {
	text-align: center;
	color: #D50D0E;
	margin-top: 20%;
}