body {
	margin: 0;
	font-size: 15px;
}

.flex {
	display: flex;
	align-items: center;
}

h2, h3, h1 {
	margin: 0;
}

.el-input__inner::-webkit-input-placeholder {
	color: #999999;
}

/* Firefox 4-18 */
.el-input__inner:-moz-placeholder {
	color: #999999;
}

/* Firefox 19-50 */
.el-input__inner::-moz-placeholder {
	color: #999999;
}

/* - Internet Explorer 10–11
	 - Internet Explorer Mobile 10-11 */
.el-input__inner:-ms-input-placeholder {
	color: #999999 !important;
}

/* Edge (also supports ::-webkit-input-placeholder) */
.el-input__inner::-ms-input-placeholder {
	color: #999999;
}

/* CSS Working Draft */
.el-input__inner::placeholder {
	color: #999999;
}

::-webkit-scrollbar { /*滚动条整体部分，其中的属性有width,height,background,border等（就和一个块级元素一样）（位置1）*/
	width: 5px;
	height: 5px;
}


::-webkit-scrollbar-track { /*外层轨道，可以用display:none让其不显示，也可以添加背景图片，颜色改变显示效果（位置3）*/
	display: none;
}

::-webkit-scrollbar-track-piece { /*内层轨道，滚动条中间部分（位置4）*/
	background: rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-thumb { /*滚动条里面可以拖动的那部分（位置5）*/
	background: #0067A6;
	border-radius: 2px;
}

.commonDia {
	display: flex;
	align-items: center;
	justify-content: center;

	.el-dialog__body {
		padding: 0 30px;
	}

	.el-dialog {
		margin-bottom: 0;
	}
}

.el-date-table td.current:not(.disabled) span,
.el-date-table td.end-date span, .el-date-table td.start-date span {
	background-color: #D50D0E;
}

.el-date-table td.available:hover, .el-picker-panel__icon-btn:hover,
.el-date-picker__header-label.active,
.el-date-picker__header-label:hover, .el-date-table td.today span {
	color: #D50D0E;
}

.el-date-table td.end-date span, .el-date-table td.start-date span {
	color: #FFFFFF;
}

.dialog-footer {
	.el-button {
		height: 34px;
		padding: 0 30px;
		font-size: 15px;

	}

	.el-button--primary, .el-button--primary:focus, .el-button--primary:hover {
		background: #0067A6;
		border-color: #0067A6;
	}

	.el-button--default, .el-button--default:focus, .el-button--default:hover {
		border-color: #0067A6;
		color: #0067A6;
		background-color: #FFFFFF;
	}
}

.el-menu--vertical {
	.el-menu-item-group__title {
		display: none;
	}

	.el-menu-item.is-active {
		color: #0067A6;
	}
}

.searchCriteria {
	//background: #FFFFFF;
	border-radius: 10px;
	margin-top: 15px;
	padding: 15px 0;
	border: 1px solid #0067A6;

	.el-form-item {
		margin-bottom: 0;

		.el-input__inner, .el-form-item__label, .el-form-item__content, .el-input__icon {
			height: 32px;
			line-height: 32px;
			color: rgba(255, 255, 255, 0.5);
			border: none;
			//border-color: #565D78;
		}
		.el-form-item__label{
			color: #FFFFFF;
		}
		.el-input__inner {
			background: rgba(0, 103, 166, 0.3);

			&::placeholder {
				color: rgba(255, 255, 255, 0.5);
			}
		}
		.el-date-editor .el-range-input{
			background: transparent;
			color: rgba(255, 255, 255, 0.5);
		}
	}


	.mb-1 {
		margin-bottom: 15px;
	}

	.averageWid {
		margin-left: 5%;
	}

	.el-date-editor.el-input, .el-date-editor.el-input__inner, .el-input__inner {
		width: 234px;
	}

	.el-date-editor .el-range-separator {
		line-height: 24px;
	}

	.btnGroup {
		text-align: center;

		.el-button {
			width: 92px;
			height: 32px;
			background: #0067A6;
			border-radius: 5px;
			padding: 0;
			border: none;
			color: #FFFFFF;

			&:last-child {
				border: 1px solid #0067a6;
				background-color: transparent;
				color: #0067A6;
			}
		}
	}

}
